import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function Georgia() {
  return (
    <Layout>
      <Helmet title="Georgia | Vance Joy" />

      <h1>Georgia</h1>

      <h2>Vance Joy</h2>

      <h4>Key: Em</h4>

      <h4>Tempo: 94</h4>

      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>She is something to behold</p>
          <p>Elegant and bold</p>
          <p>She is electricity</p>
          <p>Running to my soul, oh</p>
        </Verse>
        <Chorus>
          <p>And I could easily lose my mind</p>
          <p>The way you kiss me will work each time</p>
          <p>Calling me to come back to bed</p>
          <p>Singing Georgia on my mind</p>
          <p>And I, and I</p>
        </Chorus>
        <Verse>
          <p>Lips generous and warm</p>
          <p>You build me up like steps</p>
          <p>Eyes innocent and wild</p>
          <p>Remind me what it's like, oh</p>
        </Verse>
        <Chorus>
          <p>And I could easily lose my mind</p>
          <p>The way you kiss me will work each time</p>
          <p>Pulling me back into the flames</p>
          <p>And I'm burning up again, I'm burning up</p>
          <p>And I, I never understood what was at stake</p>
          <p>I never thought your love was worth it's wait</p>
          <p>Well now you've come and gone</p>
          <p>I finally worked it out, I worked it out</p>
        </Chorus>
        <Bridge>
          <p>I never should have told you</p>
          <p>I never should have let you see inside</p>
          <p>Don't want it troubling in your mind</p>
          <p>Won't you let it be (X3)</p>
        </Bridge>
        <Chorus>
          <p>And I could easily lose my mind</p>
          <p>The way you kiss me will work each time</p>
          <p>Pulling me back into the flames</p>
          <p>And I'm burning up again, I'm burning up</p>
          <p>And I, I never understood what was at stake</p>
          <p>I never thought your love was worth its wait</p>
          <p>Well now you've come and gone</p>
          <p>I finally worked it out, I worked it out, oh, ooh, oh</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
